import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../core/app.service';
import { MX_API_URL, STATE_TYPE_ID } from '../core/app.constant';
@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {

  results: any = [];
  baseResults: any = [];
  localResult: any = [];
  longResult: any = [];
  internationalResult: any = [];
  validatedResult: any = [];
  iamResult: any = [];
  nonIamResult: any = [];
  trustedResult: any = [];
  todayDate = new Date();
  currYear = this.todayDate.getFullYear();
  page = 1;
  pageSize = 10;
  predicate!: string;
  ascending!: boolean;
  searchQuery?: any;
  searchQueryLink = null;
  adv: any;
  isLoading = true;
  scopeFilter: any = [];

  constructor(private service: AppService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.service.searchresult(params).subscribe((response) => {
        this.baseResults = response.body;
        this.initFilter();
        if (this.results && this.results.length > 0) {
          this.service.advresult(params).subscribe((r) => {
            this.adv = r.body.serviceprovider;
            this.adv.resultTagline = r.body.resultTagline;
            if (this.adv) {
              this.isIamMember(this.adv);
              this.removeAdv(this.adv, this.results);
              this.removeAdv(this.adv, this.baseResults);
            }
          });
        }

        this.isLoading = false;
      });
      if (params.loc && params.svc) {
        this.searchQuery = params.loc + ' & ' + params.svc;
        // this.searchQueryLink = getSearchQueryLink(params.loc);
      } else if (params.loc) {
        this.searchQuery = params.loc + ' Licensing & Compliance';
        this.getSearchQueryLink(params.loc);
      } else if (params.svc) {
        this.searchQuery = params.svc;
      }
    });
  }

  initFilter(): void {
    this.trustedResult = this.baseResults.filter(sp => (sp.trustedMoverDisplayControl !== null || sp.mxstatus.lookupcode === '5'));

    // TODO: Do we need to separate IAMTrusted from validated?
    this.validatedResult = this.baseResults.filter(sp => sp.mxstatus.lookupcode === '4');
    this.baseResults.forEach(sp => {
      if (sp.scopeCapabilities) {
        // local
        const local = sp.scopeCapabilities.filter(s => s.capabilityType.id === 5950);
        if (local && local.length > 0) {
          this.localResult.push(sp);
        }

        // long
        const long = sp.scopeCapabilities.filter(s => s.capabilityType.id === 5951);
        if (long && long.length > 0) {
          this.longResult.push(sp);
        }
        // international
        const international = sp.scopeCapabilities.filter(s => s.capabilityType.id === 5952);
        if (international && international.length > 0) {
          this.internationalResult.push(sp);
        }
      }

      if (sp.memberAssociations) {
        const iam = sp.memberAssociations.filter(s => s.memberAssociation.id === 800);
        if (iam && iam.length > 0) {
          this.isIamMember(sp);
          this.iamResult.push(sp);
        } else {
          this.nonIamResult.push(sp);
        }
      }
    });

    //
    this.results = [];
    this.results = this.results.concat(this.trustedResult.filter((item) => this.results.indexOf(item) < 0));
    this.results = this.results.concat(this.validatedResult.filter((item) => this.results.indexOf(item) < 0));
    this.results = this.results.concat(this.iamResult.filter((item) => this.results.indexOf(item) < 0));
    this.results = this.results.concat(this.nonIamResult.filter((item) => this.results.indexOf(item) < 0));
  }

  mxEmail(emailid: any): void {
    console.log('mxEmail...' + emailid);
    this.sendMail(emailid, 'Query from IAMTrusted.org', '');
  }

  sendMail(emailId: any, subject: any, message: any): void {
    window.open('mailto:' + emailId + '?subject=' + subject + '&body=' + message, '_self');
  }

  getApprovalStatusIndicator(mxstatus: any, appFlag: any, expiryDate: any, reviewDate: any): any {
    if ((mxstatus === '3'
      || mxstatus === '4'
      || mxstatus === '5')) {

      if (expiryDate == null && reviewDate == null && appFlag === true) {
        return 'color:green';
      }

      if (expiryDate != null) {
        const expDate = new Date(expiryDate);
        if (appFlag === true && expDate > this.todayDate) {
          return 'color:green';
        }
      }

      if (reviewDate != null) {
        const revDate = new Date(reviewDate);
        if (appFlag === true && revDate > this.todayDate) {
          return 'color:green';
        }
      }

      return 'color:orange';
    }

  }

  getApprovalStatusIndicatorMemberships(mxstatus: any, appFlag: any, expiryDate: any, reviewDate: any, memberAssoc: any): any {

    if ((mxstatus === '3'
      || mxstatus === '4'
      || mxstatus === '5')) {

      if (expiryDate == null && reviewDate == null && appFlag === true) {
        return 'color:green';
      }

      if (expiryDate != null) {
        const eDate = new Date(expiryDate);
        if (appFlag === true && eDate > this.todayDate) {
          return 'color:green';
        } else {
          return 'color:orange';
        }
      }

      if (reviewDate != null) {
        const revDate = new Date(reviewDate);
        if (appFlag === true && revDate > this.todayDate) {
          return 'color:green';
        }
      }

      if (memberAssoc.memberType && memberAssoc.memberStatus) {

        if (memberAssoc.memberType.flex2 === 'Y') {

          if (memberAssoc.memberStatus.id === 1201) {

            return 'color:green';

          } else {

            return 'color:orange';
          }
        }
      }

      // console.log('2 :
      // '+JSON.stringify(memberAssoc.memberStatus.id));

      return 'color:orange';
    }

    if (memberAssoc.memberAssociation.id === 800) {
      if (expiryDate != null) {
        const expDate = new Date(expiryDate);
        if (appFlag === true && expDate > this.todayDate) {
          return 'color:green';
        } else {
          return 'color:orange';
        }
      }
    }

  }

  skypeAndWhatsappUrl(contact: any, type: any, isSkype: any) {

    if (contact.skypeId != null || contact.whatsapp != null) {
      if (this.isProviderAvailable(contact)) {
        if (isSkype) {
          window.open('skype:' + contact.skypeId + '?' + type);
        } else {
          window.open('https://api.whatsapp.com/send?phone=' + contact.whatsapp);
        }

      } else {
        if (contact.timezone) {
          const localTime = this.service.getLocalDateTime(contact.timezone.flex1);
          let days = '';
          if (contact.skypeMon) { days = days + 'Mon&nbsp;'; }
          if (contact.skypeTue) { days = days + 'Tue&nbsp;'; }
          if (contact.skypeWed) { days = days + 'Wed&nbsp;'; }
          if (contact.skypeThr) { days = days + 'Thr&nbsp;'; }
          if (contact.skypeFri) { days = days + 'Fri&nbsp;'; }
          if (contact.skypeSat) { days = days + 'Sat&nbsp;'; }
          if (contact.skypeSun) { days = days + 'Sun&nbsp;'; }
          const body = '<fieldset class="scheduler-border"><legend class="scheduler-border">Normal Business Hours</legend>'
            + '<table><tr>'
            + '<td><span style="height: 25px;"> Local time:&nbsp;</span></td>'
            + '<td><a style="pointer-events: none;">' + localTime + '</a></td>'
            + '</tr>'
            + '<tr>'
            + '<td><span style="height: 25px;"> Available days:&nbsp;</span></td>'
            + '<td><a style="pointer-events: none;">' + days + '</a></td>'
            + '</tr>'
            + '<tr>'
            + '<td><span style="height: 25px;"> Office hours:&nbsp; </span></td>'
            + '<td><a style="pointer-events: none;">' + contact.skypeTimeFrom + ' - ' + contact.skypeTimeTo + ' hrs</a></td>'
            + '</tr></table></fieldset>';
          alert(body);
        }
        alert('Currently Not Available!');
      }
    }
  }

  isProviderAvailable(address: any): any {

    if (!address.timezone || (!address.skypeTimeFrom && !address.skypeTimeTo)) {
      return true;
    }

    if (address.timezone) {
      const date = this.service.getLocalDateTime(address.timezone.flex1);
      const nd = this.service.getUTCDate(address.timezone.flex1);

      const dateTo = nd.getFullYear() + '/' + (nd.getMonth() + 1) + '/' + nd.getDate() + ' ' + address.skypeTimeTo + ':' + nd.getSeconds();
      // tslint:disable-next-line:max-line-length
      const dateFrom = nd.getFullYear() + '/' + (nd.getMonth() + 1) + '/' + nd.getDate() + ' ' + address.skypeTimeFrom + ':' + nd.getSeconds();
      // console.log(" 1421. date = " + date + " dateTo = " + dateTo + " dateFrom = " + dateFrom);
      if (Date.parse(date) >= Date.parse(dateFrom) && Date.parse(date) <= Date.parse(dateTo)) {
        const day = nd.getDay();
        // console.log("1424. day = " + day);
        switch (day) {
          case 1:
            if (address.skypeMon) { return true; }
            break;
          case 2:
            if (address.skypeTue) { return true; }
            break;
          case 3:
            if (address.skypeWed) { return true; }
            break;
          case 4:
            if (address.skypeThr) { return true; }
            break;
          case 5:
            if (address.skypeFri) { return true; }
            break;
          case 6:
            if (address.skypeSat) { return true; }
            break;
          case 0:
            if (address.skypeSun) { return true; }
            break;
        }
      }
    }
    return false;
  }

  transition(): void {
    /*
    this.router.navigate(['./'], {
      relativeTo: this.activatedRoute.parent,
      queryParams: {
        page: this.page,
        sort: this.predicate + ',' + (this.ascending ? 'asc' : 'desc'),
      },
    });
    */
  }

  handleFilter($event) {
    if ($event.target.checked === true) {
      this.scopeFilter.push($event.target.value);
    } else {
      this.scopeFilter.pop($event.target.value);
    }
    this.filterResult();
    console.log(' 281 = ' + this.scopeFilter);
  }

  filterResult(): void {
    // Trusted Validated IAM-Member Non-Member
    this.scopeFilter = this.scopeFilter.sort();
    if (this.scopeFilter.length > 0) {
      this.results = [];
      this.scopeFilter.forEach(id => {
        if (id === '1') {
          // this.results = this.results.concat(this.localResult);
          this.results = this.results.concat(this.trustedResult.filter((item) => this.results.indexOf(item) < 0));
        } else if (id === '4') {
          // this.results = this.results.concat(this.validatedResult);
          this.results = this.results.concat(this.validatedResult.filter((item) => this.results.indexOf(item) < 0));
        } else if (id === '800') {
          // this.results = this.results.concat(this.validatedResult);
          this.results = this.results.concat(this.iamResult.filter((item) => this.results.indexOf(item) < 0));
        } else if (id === '5950') {
          // this.results = this.results.concat(this.localResult);
          this.results = this.results.concat(this.localResult.filter((item) => this.results.indexOf(item) < 0));
        } else if (id === '5951') {
          // this.results = this.results.concat(this.longResult);
          this.results = this.results.concat(this.longResult.filter((item) => this.results.indexOf(item) < 0));
        } else if (id === '5952') {
          // this.results = this.results.concat(this.internationalResult);
          this.results = this.results.concat(this.internationalResult.filter((item) => this.results.indexOf(item) < 0));
        } else if (id === '6000') {
          // this.results = this.results.concat(this.validatedResult);
          this.results = this.results.concat(this.nonIamResult.filter((item) => this.results.indexOf(item) < 0));
        }
      });
    } else {
      this.results = this.baseResults;
    }
  }

  removeAdv(adv: any, result: any): void {
    const index = result.indexOf(adv, 0);
    if (index > -1) {
      result.splice(index, 1);
    }
  }

  getComplianceLicensesLink(sp: any, type: any, lookup: any): void {

    if (lookup.certificateType.lookupvalue === 'DOT') {
      window.open('https://safer.fmcsa.dot.gov/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=USDOT&original_query_param=NAME&query_string=' + lookup.certificatenumber, '_blank');
    } else if (lookup.certificateType.lookupvalue === 'FMC') {
      this.downloadFmcCert(lookup.certificatenumber);
    } else if (lookup.website) {
      window.open(lookup.website, '_blank');
    } else {
      if (sp && sp.serviceproviderFiles && sp.mxstatus.lookupcode === '4') {

        for (let i = 0; i < sp.serviceproviderFiles.length; i++) {
          if (sp.serviceproviderFiles[i]
            && sp.serviceproviderFiles[i].active === true
          ) {
            const tkns = sp.serviceproviderFiles[i].imageName.split('_');
            const itemId = tkns[1];
            const itemType = sp.serviceproviderFiles[i].image_type;
            const path = sp.serviceproviderFiles[i].imagePath.replace('/apps', '');
            if (itemType === type && lookup.certificateType.lookupvalue === itemId) {
              window.open(MX_API_URL + path, '_blank');
            }
          }
        }

      }

      if (type === 'certificate') {
        window.open(MX_API_URL + '/customer/images/' + lookup.certificateType.lookupvalue + '_cert.png', '_blank');
      }
    }
  }

  getWebsitLink(sp: any, mbrAss: any): boolean {

    // tslint:disable-next-line:max-line-length
    if ((mbrAss.memberAssociation.lookupvalue === 'IAM'
      || mbrAss.memberAssociation.lookupvalue === 'MXVAL')
      && mbrAss.memberStatus && mbrAss.memberStatus.lookupcode === 'ACT') {
      this.service.downloadCert(mbrAss.memberAssociation.lookupvalue, sp.id).subscribe(res => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL, '_blank');
        return true;
      });
    } else if (sp && sp.mxstatus.lookupcode === '4') {
      if (mbrAss.website) {
        window.open(mbrAss.website, '_blank');
        return true;
      } else if (mbrAss.memberAssociation.flex4 === 'Y' && (mbrAss.memberAssociation.lookupvalue === 'ISA' ||
        mbrAss.memberAssociation.lookupvalue === 'ILN')) {
        const type = 'Validation_Cert.pdf';
        window.open(MX_API_URL + '/customer/images/' + mbrAss.memberAssociation.lookupvalue + '_' + type, '_blank');
        return true;
      } else if (mbrAss.memberAssociation.flex2 === 'D') {
        const type = 'cert.png';
        window.open(MX_API_URL + '/customer/images/' + mbrAss.memberAssociation.lookupvalue + '_' + type, '_blank');
        return true;
      } else {
        this.getComplianceLicensesLink(sp, 'membership', mbrAss.memberAssociation);
        return true;
      }
    }
    return true;
  }
  downloadFmcCert(organization: any): void {
    this.service.getFmcCert(organization).subscribe(res => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });
  }

  isIamMember(sp): boolean {
    const iam = sp.memberAssociations.filter(s => s.memberAssociation.id === 800);
    if (iam && iam.length > 0) {
      sp.isIamMember = true;
      return true;
    }
    return false;
  }

  errorHandler(event) {
    event.target.src = '../assets/img/nologo.png';
  }

  getSearchQueryLink(loc: any): void {
    if (loc) {
      const splitted = loc.split(',');
      if (splitted.length > 2) {
        const code = splitted[1].trim();
        const state = code.split(' ');
        this.getComplienceLink(state[0]);
      } else if (splitted.length === 2) {
        const country = splitted[1].trim();
        if (country === 'USA') {
          const code = splitted[0].trim();
          console.log(' 447.' + code);
          this.getComplienceLink(code);
        }
      }
    }
  }

  getComplienceLink(code: any): void {
    this.service.licensingCompliance(STATE_TYPE_ID, code).subscribe(
      res => {
        const lookup = res.body;
        if (lookup.flex3 && lookup.flex3.length > 1) {
          this.searchQueryLink = lookup.flex3;
        }
      }
    );
  }
}


