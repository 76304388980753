import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../core/app.service';
import { Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  loc?: any = '';
  lat?: any = '';
  lng?: any = '';
  range?: any = 50;
  company?: any = '';
  companyResult?: string[] = [];
  lastkeydown2 = 0;
  query?: any = 'q';
  size?: any = 10;
  sort?: any = 'companylegalname,asc';
  page?: any = 0;
  ctry?: any = '';
  spc?: any = '10,11';
  options = {
    fields: ['name', 'geometry.location', 'address_components', 'formatted_address']
  };
  constructor(private service: AppService, private router: Router) { }

  ngOnInit(): void {
  }

  public handleAddressChange(address: Address) {
    this.lat = address.geometry.location.lat();
    this.lng = address.geometry.location.lng();
    this.loc = address.formatted_address;
    const filtered_array = address?.address_components?.filter(function (address_component) {
      return address_component.types.includes('country');
    });
    this.ctry = filtered_array?.length ? filtered_array[0].long_name : '';
  }
  search(): void {
    // tslint:disable-next-line:max-line-length
    this.router.navigate(['/result'], {
      queryParams: {
        loc: this.loc, lat: this.lat, lng: this.lng, ctry: this.ctry, range: this.range, spc: this.spc,
        svc: this.company ? this.company.split(',')[0] : '', query: this.query, size: this.size, sort: this.sort, page: this.page
      }
    });
  }

  getCompany() {
    if (this.company && this.company.length > 1) {
      this.service.getCompany(this.company).subscribe(response => {
        this.companyResult = response.body.map(function (item) {
          return item['searchResult'];
        });
      });
    }
  }

}
