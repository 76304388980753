import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
// tslint:disable-next-line:max-line-length
import { MX_COMPANY_SEARCH_URL, MX_FMC_URL, MX_COMPANY_SEARCH_ADV_URL, MX_COMPANY_SEARCH_FULLRESULT_URL, MX_CERT_URL, MX_LOOKUPS } from './app.constant';
import { ICompanySearch } from './app.model';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    constructor(private http: HttpClient) { }
    getCompany(company?: string): Observable<HttpResponse<ICompanySearch[]>> {
        return this.http.get<ICompanySearch[]>(MX_COMPANY_SEARCH_URL, {
            params: {
                query: company,
            },
            observe: 'response'
        });
    }

    searchresult(params: any): Observable<any> {
        return this.http.get(MX_COMPANY_SEARCH_FULLRESULT_URL, {
            params,
            observe: 'response'
        });
    }

    advresult(params: any): Observable<any> {
        return this.http.get(MX_COMPANY_SEARCH_ADV_URL, {
            params,
            observe: 'response'
        });
    }

    getLocalDateTime(timezoneOffset: any): any {
        const nd = this.getUTCDate(timezoneOffset);
        const minutes = nd.getMinutes() > 9 ? '' + nd.getMinutes() : '0' + nd.getMinutes();
        const seconds = nd.getSeconds() > 9 ? '' + nd.getSeconds() : '0' + nd.getSeconds();
        // tslint:disable-next-line:max-line-length
        const date = (nd.getMonth() + 1) + '/' + +nd.getDate() + '/' + nd.getFullYear() + ' ' + nd.getHours() + ':' + minutes + ':' + seconds;
        return date;
    }

    getUTCDate(timezoneOffset) {
        const today = new Date();
        const utc = today.getTime() + (today.getTimezoneOffset() * 60000);
        const newOffset = timezoneOffset.split(':');
        // tslint:disable-next-line:radix
        const x = parseInt(newOffset[1]);
        if (x > 1) {
            timezoneOffset = '' + newOffset[0] + '.5';
        }

        const nd = new Date(utc + (3600000 * timezoneOffset));
        return nd;
    }

    getFmcCert(organization: any): any {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get(MX_FMC_URL + organization, { headers: headers, responseType: 'blob' });
    }

    downloadCert(membership: any, id: any): any {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get(MX_CERT_URL + membership + '_CERT/' + id + '/%20', { headers: headers, responseType: 'blob' });
    }

    licensingCompliance(typeid: any, code: any): Observable<any> {
        return this.http.get(MX_LOOKUPS  + typeid + '/' + code, {
            observe: 'response'
        });
    }
}
